/* src/Navbar.css */

.navbar-nav {
  margin-left: auto;
  margin-right: auto;
}

.navbar-brand {
  margin-right: auto;
}

.custom-navbar {
  background-color: transparent !important;
  position: absolute;
  right: 20px;
  width: 100%;
  z-index: 1000;
}

.custom-navbar .nav-link {
  color: rgba(255, 255, 255, 0.57) !important;
}

/* Optional: Customize the hamburger icon color */
.custom-navbar .navbar-toggler-icon {
  filter: invert(1);
}

@media (max-width: 768px) {
  img {
    width: 50px;
  }
  .custom-navbar {

    right: 0px;
  }
}
