/* src/App.css */

.App {
  text-align: center;
  background-image: url("./assets/bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.App-header {
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  flex: 1;
}

.h {
  font-size: 70px;
  margin-bottom: 20px;
}

.para {
  font-size: 16px;
  /*font-family: "A Dilini";*/
}

.btn {
  margin-top: 20px;
  padding: 8px 28px !important;
  font-size: 16px !important;
  border-radius: 5px; /* Rounded corners */
  border: 2px solid #fff; /* Custom border */
}

@media (max-width: 768px) {
  .App {
    background-position-x: -550px;
  }
  .App-header {
    margin-top: 50px;
  }
  .h {
    font-size: 40px;
  }
  .para {
    padding: 0 20px;
    font-size: 14px !important;
    /*font-family: "A Dilini";*/
  }
  .btn {
    margin-top: 20px;
    padding: 8px 20px !important;
    font-size: 14px !important;
  }
}
