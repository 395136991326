/* src/components/Footer.css */

.footer {
    background-color: transparent;
    color: white;
    text-align: center;
    padding-bottom: 30px;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 14px;
}

.social-icons {
    margin-top: 10px;
}

.social-icons a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
}

.social-icons a:hover {
    color: white; /* Change color on hover */
}
